import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment, Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import { useFilters, FiltersState } from 'hooks/useFilters';
import { recipes } from 'data';
import RecipesTable from 'components/RecipesTable';
import CreateRecipeDialog from 'components/dialogs/CreateRecipeDialog';
import Filter from 'components/Filter';
import styles from './Recipes.module.scss';

export default function Recipes() {
  const { t } = useTranslation();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const { filteredRecipes, filtersArr, activeFilters, setActiveFilters } =
    useFilters({ recipes });

  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
  };

  return (
    <>
      <div className={styles.root}>
        <Box className={styles.head}>
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            onClick={openCreateDialog}
          >
            {t('recipe-actions.new-recipe')}
          </Button>
          <Box className={styles.filters}>
            {filtersArr.map((filter) => (
              <Filter
                {...filter}
                key={filter.filterType}
                activeFilters={
                  activeFilters[filter.filterType as keyof FiltersState]
                }
                setActiveFilters={setActiveFilters}
              />
            ))}
            <TextField
              placeholder={t('common.search') || ''}
              sx={{
                width: '300px',

                '.MuiTextField-root .MuiInputBase-input input': {
                  textAlign: 'left',
                },
              }}
              InputProps={{
                sx: {
                  '& .MuiInputBase-input': {
                    textAlign: 'left',
                    padding: 0,
                  },
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <div className={styles.content}>
          <RecipesTable rows={filteredRecipes} />
        </div>
      </div>
      <div className='default-layout_footer'>
        <Box className={styles.buttons}>
          <Button startIcon={<Edit />} variant='contained'>
            {t('recipe-actions.load-recipe')}
          </Button>
        </Box>
      </div>
      <CreateRecipeDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </>
  );
}
