import { useEffect, useState } from 'react';
import useTypedSelector from 'hooks/useTypedSelector';
import { favoritesTableHead } from 'constants/tables';
import { FavoriteItemType } from 'redux/recipes/recipesType';
import { devicesSelector } from 'redux/recipes/recipesSelectors';
import FavoriteItem from 'components/ParametersTable/rows/FavoriteItem';
import ParametersTable from 'components/ParametersTable';

export default function FavoritesTabPanel() {
  const devices = useTypedSelector(devicesSelector);
  const [favorites, setFavorites] = useState<any[]>([]);

  useEffect(() => {
    const allDevicesParams = Object.values(devices).reduce(
      (acc: FavoriteItemType[], { componentCfg, parameters, group, name }) => {
        acc.push(
          ...Object.values(componentCfg).map((param) => ({
            ...param,
            group,
            name,
          })),
          ...Object.values(parameters).map((param) => ({
            ...param,
            group,
            name,
          }))
        );
        return acc;
      },
      []
    );
    const allFavorites = allDevicesParams.filter(({ favorite }) => favorite);

    setFavorites(allFavorites);
  }, [devices]);

  return (
    <>
      <ParametersTable tableHead={favoritesTableHead}>
        {favorites.map((param, index) => {
          const { group, name, ...rest } = param;
          return (
            <FavoriteItem item={rest} group={group} name={name} key={index} />
          );
        })}
      </ParametersTable>
      <ParametersTable title='recipe.version-history' />
    </>
  );
}
