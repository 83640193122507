import { useAppDispatch } from 'redux/store';
import useTypedSelector from 'hooks/useTypedSelector';
import {
  devicesSelector,
  selectedDeviceSelector,
} from 'redux/recipes/recipesSelectors';
import { changeParameter } from 'redux/recipes/recipesSlice';
import DevicesTable from 'components/DevicesTable';
import ParametersItem from 'components/ParametersTable/rows/ParametersItem';
import ParametersTable from 'components/ParametersTable';

export default function ParametersTabPanel() {
  const dispatch = useAppDispatch();
  const devices = useTypedSelector(devicesSelector);
  const selectedDevice = useTypedSelector(selectedDeviceSelector)!;
  const parameters = devices[selectedDevice.group]?.parameters;
  const parametersArr = Object.values(parameters || {});

  const onCounterChange = (id: string, value: number) => {
    dispatch(
      changeParameter({
        deviceId: selectedDevice?.group,
        componentId: id,
        content: { setPoint: { ...parameters[id].setPoint, value: value } },
      })
    );
  };

  const onFavoriteChange = (id: string) => {
    dispatch(
      changeParameter({
        deviceId: selectedDevice?.group,
        componentId: id,
        content: { favorite: !parameters[id].favorite },
      })
    );
  };

  return (
    <>
      <DevicesTable />
      <ParametersTable title={selectedDevice.name}>
        {parametersArr.map((param, index) => (
          <ParametersItem
            key={param.id}
            item={param}
            index={index + 1}
            onFavoriteChange={onFavoriteChange}
            onCounterChange={onCounterChange}
          />
        ))}
      </ParametersTable>
    </>
  );
}
