import { TableCell, TableRow, Typography } from '@mui/material';
import { Version } from 'redux/recipes/recipesType';
import ConnnectionTrueIcon from 'components/icons/ConnectionTrueIcon';
import ConnectionFalseIcon from 'components/icons/ConnectionFalseIcon';

interface Props {
  version: Version;
}

export default function VersionRow({ version }: Props) {
  const { number, date, time, released, description } = version;

  return (
    <TableRow>
      <TableCell>{number}</TableCell>
      <TableCell>
        <Typography variant='body2' component='p'>
          {date}
        </Typography>
        <Typography variant='subtitle2' component='p'>
          {time}
        </Typography>
      </TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>
        {released ? <ConnnectionTrueIcon /> : <ConnectionFalseIcon />}
      </TableCell>
    </TableRow>
  );
}
