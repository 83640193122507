import { SvgIconProps, SvgIcon } from '@mui/material';

export default function ConnectionTrueIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12ZM15.5938 10.625C15.9375 10.2812 15.9375 9.75 15.5938 9.40625C15.25 9.0625 14.7188 9.0625 14.375 9.40625L11 12.7812L9.59375 11.4062C9.25 11.0625 8.71875 11.0625 8.375 11.4062C8.03125 11.75 8.03125 12.2812 8.375 12.625L10.375 14.625C10.7188 14.9688 11.25 14.9688 11.5938 14.625L15.5938 10.625Z'
        fill='#44AD4E'
      />
    </SvgIcon>
  );
}
