import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableRow, TableCell, Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { RecipeType } from 'redux/recipes/recipesType';
import { useAppDispatch } from 'redux/store';
import { resetState, setSelectedRecipe } from 'redux/recipes/recipesSlice';
import RecipeMenu from 'components/RecipeMenu';
import ArchiveIcon from 'components/icons/ArchiveIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import DuplicateIcon from 'components/icons/DuplicateIcon';
import EditIcon from 'components/icons/EditIcon';
import ExportIcon from 'components/icons/ExportIcon';
import ConnnectionTrueIcon from 'components/icons/ConnectionTrueIcon';
import ConnectionFalseIcon from 'components/icons/ConnectionFalseIcon';

export default function RecipeItem({ recipe }: { recipe: RecipeType }) {
  const { id, durchburch, murchburch, type, status, description } = recipe;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const onEditClick = () => {
    dispatch(resetState());
    dispatch(setSelectedRecipe(recipe));
    navigate(`/${recipe.id}`);
  };

  const recipeMenu = [
    { title: t('common.edit'), icon: <EditIcon />, onClick: onEditClick },
    { title: t('common.duplicate'), icon: <DuplicateIcon /> },
    { title: t('common.export-print'), icon: <ExportIcon /> },
    { title: t('common.archive'), icon: <ArchiveIcon /> },
    { title: t('common.delete'), icon: <DeleteIcon /> },
  ];

  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell>{id}</TableCell>
      <TableCell>{durchburch}</TableCell>
      <TableCell>{murchburch}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>
        {status === 'Released' ? (
          <ConnnectionTrueIcon />
        ) : (
          <ConnectionFalseIcon />
        )}
      </TableCell>
      <TableCell>
        <Button onClick={toggleMenu} variant='icon' sx={{ float: 'right' }}>
          <MoreHorizIcon />
        </Button>
        {menuOpen && <RecipeMenu list={recipeMenu} />}
      </TableCell>
    </TableRow>
  );
}
