import useTypedSelector from 'hooks/useTypedSelector';
import { useTranslation } from 'react-i18next';
import { Box, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { isControlsDisabledSelector } from 'redux/recipes/recipesSelectors';
import styles from '../Parameters.module.scss';

interface Props {
  property: string;
  unit?: string;
  name: string;
  value?: number | string;
  fullWidth?: boolean;
  alignLeft?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function BaseParameterItem({
  property,
  unit,
  value,
  name,
  fullWidth,
  alignLeft,
  onChange,
}: Props) {
  const isControlsDisabled = useTypedSelector(isControlsDisabledSelector);
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{t(property)}</TableCell>
      <TableCell>
        <Box className={styles.properties}>
          {unit && (
            <Typography variant='body2' component='span'>
              {unit}
            </Typography>
          )}
          <TextField
            disabled={isControlsDisabled}
            value={value}
            sx={{
              marginRight: '8px',
              ...(alignLeft && {
                '.MuiTextField-root .MuiInputBase-input input': {
                  textAlign: 'left',
                },
              }),
            }}
            InputProps={{
              sx: {
                ...(alignLeft && {
                  '& .MuiInputBase-input': {
                    textAlign: 'left',
                    paddingLeft: '8px',
                  },
                }),
              },
            }}
            fullWidth={fullWidth}
            name={name}
            onChange={onChange}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}
