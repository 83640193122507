import { SvgIconProps, SvgIcon } from '@mui/material';

export default function DuplicateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M4 11C4 9.90625 4.875 9 6 9H8V13C8 14.6562 9.3125 16 11 16H15V18C15 19.125 14.0938 20 13 20H6C4.875 20 4 19.125 4 18V11ZM11 15C9.875 15 9 14.125 9 13V6C9 4.90625 9.875 4 11 4H18C19.0938 4 20 4.90625 20 6V13C20 14.125 19.0938 15 18 15H11Z'
        
      />
    </SvgIcon>
  );
}
