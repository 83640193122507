import Recipe from 'pages/Recipe/Recipe';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DefaultLayout from './layouts/DefaultLayout';
import Recipes from './pages/Recipes/Recipes';
import UIKit from './pages/UIKit';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<DefaultLayout />}>
          <Route index element={<Recipes />} />
          <Route path='/:id' element={<Recipe />} />
          <Route path='/ui-kit' element={<UIKit />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
