import { Remove, Add } from '@mui/icons-material';
import { TextField, Slider, Button } from '@mui/material';
import styles from './Counter.module.scss';

interface CounterProps {
  value: number;
  maxValue: number;
  minValue: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

const STEP = 5;

export default function Counter({
  value,
  maxValue,
  minValue,
  onChange,
  disabled,
}: CounterProps) {
  const add = () => {
    if (value + STEP > maxValue) return;
    onChange(value + STEP);
  };

  const remove = () => {
    if (value - STEP < minValue) return;
    onChange(value - STEP);
  };

  return (
    <div className={styles.root}>
      <div className={styles.input}>
        <TextField
          type='number'
          value={value}
          variant='standard'
          InputProps={{
            readOnly: true,
            sx: {
              '.MuiInputBase-input': {
                padding: 0,
              },
            },
          }}
        />
        <Slider
          value={value}
          className={styles.slider}
          min={minValue}
          max={maxValue}
        />
      </div>
      <Button
        onClick={remove}
        disabled={disabled}
        sx={{ border: 'none' }}
        className={styles.button}
        variant='icon'
      >
        <Remove />
      </Button>
      <Button
        onClick={add}
        disabled={disabled}
        sx={{ border: 'none' }}
        className={styles.button}
        variant='icon'
      >
        <Add />
      </Button>
    </div>
  );
}
