export const WHITE_DEFAULT = '#FFFFFF';
export const WHITE_PRIMARY = '#F7F7F7';
export const WHITE_SECONDARY = '#E5E4DA';
export const GREY_MAIN = '#646567';
export const GREY_LIGHT = '#C5C4C7';
export const GREY_10 = '#FAFAFA';
export const GREY_15 = '#F6F6F6';
export const GREY_20 = '#E9EBEC';
export const GREY_30 = '#D9DBDD';
export const GREY_40 = '#B6B8BB';
export const GREY_60 = '#9C9EA0';
export const BLACK = '#000000';
export const BLACK_20 = '#B7B7B7';
export const RED = '#AC0D19';

export const GRADIENT_ONE = 'linear-gradient(180deg, #75777C 0%, #5A5C60 100%)';
export const GRADIENT_TWO = 'linear-gradient(180deg, #EBEDEE 0%, #D9DCDD 100%)';
