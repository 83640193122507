import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { parametersTableStyles } from 'constants/tables';
import { TableHeadType } from 'types/tables';

interface Props {
  children?: React.ReactNode;
  title?: string;
  tableHead?: TableHeadType[];
}

export default function ParametersTable({ children, title, tableHead }: Props) {
  const { t, i18n } = useTranslation();

  return (
    <TableContainer sx={parametersTableStyles}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHead
              ? tableHead.map(({ title, width, colSpan }) => (
                  <TableCell
                    key={title}
                    sx={{ width: `${width}%` }}
                    colSpan={colSpan}
                  >
                    {t(title)}
                  </TableCell>
                ))
              : title && (
                  <TableCell colSpan={100}>
                    {i18n.exists(title) ? t(title) : title}
                  </TableCell>
                )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
