import { SvgIconProps, SvgIcon } from "@mui/material";

export default function CheckedCheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' fill='#646567' />
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke='#646567' />
      <path
        d='M18.6875 7.3125C19.0938 7.6875 19.0938 8.34375 18.6875 8.71875L10.6875 16.7188C10.3125 17.125 9.65625 17.125 9.28125 16.7188L5.28125 12.7188C4.875 12.3438 4.875 11.6875 5.28125 11.3125C5.65625 10.9062 6.3125 10.9062 6.6875 11.3125L9.96875 14.5938L17.2812 7.3125C17.6562 6.90625 18.3125 6.90625 18.6875 7.3125Z'
        fill='white'
      />
    </SvgIcon>
  );
}