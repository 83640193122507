import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, MenuList } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FiltersState } from 'hooks/useFilters';
import FilterItem from './FilterItem';

interface Props {
  title: string;
  options: (string | number)[];
  filterType: keyof FiltersState;
  activeFilters: (string | number)[];
  setActiveFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
}

export default function Filter({
  title,
  options,
  filterType,
  activeFilters,
  setActiveFilters,
}: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const toggleMenu = () => setMenuOpen((prev) => !prev);

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string | number
  ) => {
    if (event.target.checked) {
      setActiveFilters((filters) => ({
        ...filters,
        [filterType]: [...filters[filterType], option],
      }));
    } else {
      setActiveFilters((filters) => ({
        ...filters,
        [filterType]: filters[filterType].filter((i) => i !== option),
      }));
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        variant='secondary'
        endIcon={
          <KeyboardArrowDownIcon
            sx={{ transform: menuOpen ? 'rotate(180deg)' : 'none' }}
          />
        }
        onClick={toggleMenu}
      >
        {t(title)}
      </Button>
      {menuOpen && (
        <MenuList
          sx={{ minWidth: '100%', maxWidth: '200px', width: 'max-content' }}
        >
          {options.map((option) => (
            <FilterItem
              key={option}
              name={filterType}
              checked={activeFilters.includes(option)}
              onChange={handleFilterChange}
              value={option}
            />
          ))}
        </MenuList>
      )}
    </Box>
  );
}
