import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function CreateRecipeDialog({ open, onClose }: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ width: '400px', padding: '16px' }}>
        Create recipe
      </DialogTitle>
      <DialogContent>
        <TextField
          placeholder='New Recipe name'
          sx={{
            '.MuiTextField-root .MuiInputBase-input input': {
              textAlign: 'left',
            },
          }}
          InputProps={{
            sx: {
              '& .MuiInputBase-input': {
                textAlign: 'left',
              },
            },
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button onClick={onClose} variant='contained'>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
