import {
  MenuItem,
  Checkbox,
  ListItemText,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import CheckboxIcon from 'components/icons/CheckboxIcon';
import CheckedCheckboxIcon from 'components/icons/CheckedCheckboxIcon';

interface Props {
  value: string | number;
  name: string;
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string | number
  ) => void;
}

export default function FilterItem({ value, name, checked, onChange }: Props) {
  return (
    <MenuItem>
      <FormGroup sx={{ width: '100%' }}>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={checked}
              onChange={(e) => onChange(e, value)}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedCheckboxIcon />}
            />
          }
          label={value}
        />
      </FormGroup>
    </MenuItem>
  );
}
