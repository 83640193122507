import { useState, useMemo } from 'react';
import { RecipeType } from 'redux/recipes/recipesType';
import { getUniqueArray } from 'utils/helpers';

export interface FiltersState {
  durchburch: (string | number)[];
  murchburch: (string | number)[];
  type: (string | number)[];
}

interface Props {
  recipes: RecipeType[];
}

export const useFilters = ({ recipes }: Props) => {
  const [activeFilters, setActiveFilters] = useState<FiltersState>({
    durchburch: [],
    murchburch: [],
    type: [],
  });

  const filteredRecipes = useMemo(() => {
    if (Object.values(activeFilters).every((l) => l.length === 0))
      return recipes;

    return recipes.filter(
      ({ durchburch, murchburch, type }) =>
        (activeFilters.durchburch.length
          ? activeFilters.durchburch.includes(durchburch)
          : true) &&
        (activeFilters.murchburch.length
          ? activeFilters.murchburch.includes(murchburch)
          : true) &&
        (activeFilters.type.length ? activeFilters.type.includes(type) : true)
    );
  }, [activeFilters, recipes]);

  const filtersArr = [
    {
      title: 'recipe.diameter',
      options: getUniqueArray(recipes.map((recipe) => recipe.durchburch)),
      filterType: 'durchburch' as keyof FiltersState,
    },
    {
      title: 'recipe.length',
      options: getUniqueArray(recipes.map((recipe) => recipe.murchburch)),
      filterType: 'murchburch' as keyof FiltersState,
    },
    {
      title: 'recipe.product-type',
      options: getUniqueArray(recipes.map((recipe) => recipe.type)),
      filterType: 'type' as keyof FiltersState,
    },
  ];

  return { filteredRecipes, filtersArr, activeFilters, setActiveFilters };
};
