import BaseDataTabPanel from 'components/tabs/BaseDataTabPanel';
import ComponentCfgTabPanel from 'components/tabs/ComponentCfgTabPanel';
import FavoritesTabPanel from 'components/tabs/FavoritesTabPanel';
import ParametersTabPanel from 'components/tabs/ParametersTabPanel';

export const tabs = [
  {
    id: 1,
    label: 'recipe.basic-data',
    content: <BaseDataTabPanel />,
  },
  {
    id: 2,
    label: 'recipe.presets',
    content: <ComponentCfgTabPanel />,
  },
  {
    id: 3,
    label: 'recipe.machine-data',
    content: <ParametersTabPanel />,
  },
  {
    id: 4,
    label: 'recipe.favourites',
    content: <FavoritesTabPanel />,
  },
];
