import { TableHeadType } from 'types/tables';
import { GREY_10, GREY_15 } from './colors';

export const recipesTableHead: TableHeadType[] = [
  {
    title: 'recipe.product-id',
    width: 6,
  },
  {
    title: 'recipe.diameter',
    width: 10,
  },
  {
    title: 'recipe.length',
    width: 10,
  },
  {
    title: 'recipe.product-type',
    width: 10,
  },
  {
    title: 'recipe.description',
    width: 53,
  },
  {
    title: 'recipe.status',
    width: 6,
  },
  {
    title: '',
    width: 5,
  },
];

export const versionsTableHead: TableHeadType[] = [
  { title: 'recipe.version-number', width: 12 },
  { title: 'recipe.date', width: 22 },
  { title: 'recipe.description', width: 52 },
  { title: 'recipe.released', width: 14 },
];

export const devicesTableHead: TableHeadType[] = [
  { title: 'recipe.group', width: 10 },
  { title: 'recipe.designation', width: 90 },
];

export const favoritesTableHead: TableHeadType[] = [
  { title: 'recipe.group' },
  { title: 'recipe.designation' },
  { title: 'recipe.setting', colSpan: 3 },
];

export const parametersTableStyles = {
  backgroundColor: GREY_15,

  '.MuiTable-root': {
    '	.MuiTableBody-root': {
      backgroundColor: GREY_10,

      '.MuiTableRow-root': {
        cursor: 'default',

        '&:nth-of-type(even), &:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
};
