import { RecipeType } from 'redux/recipes/recipesType';

export const baseData = [
  {
    id: 1,
    property: 'recipe.product-id',
    name: 'id' as keyof RecipeType,
  },
  {
    id: 2,
    property: 'recipe.diameter',
    unit: 'mm',
    name: 'durchburch' as keyof RecipeType,
  },
  {
    id: 3,
    property: 'recipe.length',
    unit: 'mm',
    name: 'murchburch' as keyof RecipeType,
  },
  {
    id: 4,
    property: 'recipe.product-type',
    name: 'type' as keyof RecipeType,
  },
  {
    id: 5,
    property: 'recipe.description',
    fullWidth: true,
    name: 'description' as keyof RecipeType,
    alignLeft: true,
  },
];
