import { TableRow, TableCell } from '@mui/material';
import { devicesTableHead } from 'constants/tables';
import { useAppDispatch } from 'redux/store';
import useTypedSelector from 'hooks/useTypedSelector';
import {
  devicesSelector,
  selectedDeviceSelector,
} from 'redux/recipes/recipesSelectors';
import { setSelectedDevice } from 'redux/recipes/recipesSlice';
import MainTable from 'components/MainTable';

export default function DevicesTable() {
  const dispatch = useAppDispatch();
  const devices = useTypedSelector(devicesSelector);
  const selectedDevice = useTypedSelector(selectedDeviceSelector);

  const onRowClick = (groupId: number) => {
    const device = devices[groupId];
    const selectedDevice = device && { group: device.group, name: device.name };

    dispatch(setSelectedDevice(selectedDevice));
  };

  return (
    <MainTable tableHead={devicesTableHead}>
      {Object.values(devices).map(({ group, name }) => (
        <TableRow
          key={group}
          selected={selectedDevice?.group === group}
          onClick={() => onRowClick(group)}
        >
          <TableCell>{group}</TableCell>
          <TableCell>{name}</TableCell>
        </TableRow>
      ))}
    </MainTable>
  );
}
