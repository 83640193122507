import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorIcon from '@mui/icons-material/Error';
import { Grid } from '@mui/material';
import { RED, WHITE_DEFAULT } from 'constants/colors';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AlertDialog({ open, onClose }: Props) {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px',
            backgroundColor: RED,
          }}
        >
          <ErrorIcon sx={{ color: WHITE_DEFAULT }} />
          <DialogTitle sx={{ padding: '0 0 0 16px', color: WHITE_DEFAULT }}>
            Rezept speichern
          </DialogTitle>
        </Grid>
        <DialogContent>
          <DialogContentText>
            Die Rezeptparameter wurden geändert. Wollen Sie das Rezept wirklich
            überschreiben?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant='contained'>
            Rezept überschreiben
          </Button>
          <Button onClick={onClose} variant='secondary'>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
