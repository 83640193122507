import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Edit, FilterAlt, Save } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState, SyntheticEvent } from 'react';
import TabPanel from '../../components/TabPanel';
import DualCounter from 'components/DualCounter';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function UIKit() {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ul
      style={{
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
      }}
    >
      <li>
        <TextField
          placeholder='Suchen'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </li>
      <li>
        <Switch {...label} defaultChecked />
        <Switch {...label} />
      </li>
      <li>
        <IconButton>
          <Edit />
        </IconButton>
      </li>
      <li>
        <Button startIcon={<FilterAlt />} variant='contained'>
          Filter
        </Button>
        <Button startIcon={<Save />} variant='contained' disabled>
          Save recipe
        </Button>
      </li>
      <li>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='Voreinstellungen' />
              <Tab label='Maschinendaten' />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Typography variant='h5'>Rüttleramplitude Füllen 5</Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant='h5'>Rüttleramplitude Füllen 6</Typography>
          </TabPanel>
        </Box>
      </li>
      <li>{/* <Counter value={0} maxValue={100} minValue={0} /> */}</li>
      <li>
        <Typography variant='h4' component='h4'>
          h4: Rüttleramplitude Füllen 6
        </Typography>
      </li>
      <li>
        <Typography variant='h5'>h5: Rüttleramplitude Füllen 6</Typography>
      </li>
      <li>
        <Typography variant='body1' component='p'>
          body1: Rüttleramplitude Füllen 6
        </Typography>
      </li>
      <li>
        <Typography variant='body2' component='p'>
          body2: Rüttleramplitude Füllen 6
        </Typography>
      </li>
      <li>{/* <AlertDialog /> */}</li>
      <li>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          <Link color='inherit' href='/' variant='h5' underline='none'>
            Recipes
          </Link>
          <Link color='inherit' href='/' variant='h5' underline='none'>
            SR-M600/250
          </Link>
        </Breadcrumbs>
      </li>
      <li>
        <DualCounter />
      </li>
    </ul>
  );
}
