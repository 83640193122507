import { Device } from 'redux/recipes/recipesType';

export const recipes = [
  {
    id: 1,
    durchburch: 250,
    murchburch: 650,
    type: 'SDB-200/50',
    status: 'Released',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  },
  {
    id: 2,
    durchburch: 500,
    murchburch: 600,
    type: 'SDB-200/500',
    status: 'Released',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa',
  },
  {
    id: 3,
    durchburch: 650,
    murchburch: 600,
    type: 'SDB-200/50',
    status: 'Not Released',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa',
  },
];

export const devices: Device[] = [
  {
    group: 1,
    name: 'devicename1',
    componentCfg: {
      reinforcementHolder1: {
        id: 'reinforcementHolder1',
        desc: 'With reinforcement holder 1',
        active: true,
        favorite: true,
      },
      reinforcementHolder2: {
        id: 'reinforcementHolder2',
        desc: 'With reinforcement holder 2',
        active: false,
        favorite: false,
      },
      reinforcementHolder3: {
        id: 'reinforcementHolder3',
        desc: 'With reinforcement holder 3',
        active: true,
        favorite: false,
      },
    },
    parameters: {
      creepSpeedUp1: {
        id: 'creepSpeedUp1',
        desc: 'Description 1',
        setPoint: {
          value: 30,
          minValue: 0,
          maxValue: 100,
          defaultValue: 0,
        },
        unit: '%',
        active: false,
        favorite: true,
      },
      posCreepSpeedUp1: {
        id: 'posCreepSpeedUp1',
        desc: 'Description 2',
        setPoint: {
          value: 0,
          minValue: 0,
          maxValue: 85,
          defaultValue: 0,
          upperWindow: 0,
          lowerWindow: 0,
        },
        unit: '%',
        active: false,
        favorite: false,
      },
    },
  },
  {
    group: 2,
    name: 'devicename2',
    componentCfg: {
      reinforcementHolder1: {
        id: 'reinforcementHolder1',
        desc: 'With reinforcement holder 1',
        active: false,
        favorite: false,
      },
      reinforcementHolder2: {
        id: 'reinforcementHolder2',
        desc: 'With reinforcement holder 2',
        active: true,
        favorite: false,
      },
      reinforcementHolder3: {
        id: 'reinforcementHolder3',
        desc: 'With reinforcement holder 3',
        active: true,
        favorite: true,
      },
    },
    parameters: {
      creepSpeedUp2: {
        id: 'creepSpeedUp2',
        desc: 'Description 1',
        setPoint: {
          value: 30,
          minValue: 0,
          maxValue: 100,
          defaultValue: 0,
        },
        unit: '%',
        active: false,
        favorite: true,
      },
      posCreepSpeedUp2: {
        id: 'posCreepSpeedUp2',
        desc: 'Description 2',
        setPoint: {
          value: 0,
          minValue: 0,
          maxValue: 85,
          defaultValue: 0,
          upperWindow: 0,
          lowerWindow: 0,
        },
        unit: '%',
        active: false,
        favorite: false,
      },
    },
  },
];

export const versions = [
  {
    number: 12.5,
    date: '12.10.2023',
    time: '14:22',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
    released: true,
  },
  {
    number: 12.4,
    date: '12.10.2023',
    time: '14:22',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
    released: true,
  },
  {
    number: 12.3,
    date: '12.10.2023',
    time: '14:22',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
    released: false,
  },
];
