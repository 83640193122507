import { SvgIconProps, SvgIcon } from "@mui/material";

export default function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke='#646567' />
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' fill='white' />
    </SvgIcon>
  );
}
