import styles from "./TabPanel.module.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function TabPanel({ value, index, children }: TabPanelProps) {
  return (
    <div hidden={value !== index} className={styles.root}>
      {value === index && <div className={styles.panel}>{children}</div>}
    </div>
  );
}
