import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { recipes } from 'data';
import devicesData from 'devices.json';
import {
  setDevices,
  setSelectedDevice,
  setSelectedRecipe,
} from './recipesSlice';

export const getRecipeThunk = createAsyncThunk<
  Promise<void>,
  number,
  { state: RootState }
>('/getRecipe', async (index, { dispatch }) => {
  try {
    dispatch(setSelectedRecipe(recipes[index - 1]));
    const devicesObject = devicesData.reduce(
      (acc, prev) => ({ ...acc, [prev.group]: prev }),
      {}
    );
    dispatch(setDevices(devicesObject));
    dispatch(setSelectedDevice(devicesData[0]));
  } catch (err) {
    console.log(err);
  }
});
