import { recipesTableHead } from 'constants/tables';
import { RecipeType } from 'redux/recipes/recipesType';
import MainTable from 'components/MainTable';
import RecipeItem from './RecipeItem';

interface Props {
  rows: RecipeType[];
}

export default function RecipesTable({ rows }: Props) {
  return (
    <MainTable tableHead={recipesTableHead} styles={{ tableLayout: 'fixed' }}>
      {rows.map((recipe) => (
        <RecipeItem recipe={recipe} key={recipe.id} />
      ))}
    </MainTable>
  );
}
