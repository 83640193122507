import { ChangeEvent } from 'react';
import { versions } from 'data';
import useTypedSelector from 'hooks/useTypedSelector';
import { versionsTableHead } from 'constants/tables';
import { baseData } from 'constants/baseData';
import { useAppDispatch } from 'redux/store';
import { selectedRecipeSelector } from 'redux/recipes/recipesSelectors';
import {
  setIsSaveButtonDisabled,
  setSelectedRecipe,
} from 'redux/recipes/recipesSlice';
import MainTable from 'components/MainTable';
import BaseParameterItem from 'components/ParametersTable/rows/BaseParameterItem';
import ParametersTable from 'components/ParametersTable';
import VersionRow from 'components/MainTable/VersionRow';

export default function BaseDataTabPanel() {
  const dispatch = useAppDispatch();
  const selectedRecipe = useTypedSelector(selectedRecipeSelector);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setSelectedRecipe({ ...selectedRecipe!, [name]: value }));
    dispatch(setIsSaveButtonDisabled(false));
  };

  return (
    <>
      <ParametersTable title='recipe.basic-data'>
        {baseData.map((param) => (
          <BaseParameterItem
            {...param}
            onChange={handleChange}
            key={param.id}
            value={selectedRecipe?.[param.name]}
          />
        ))}
      </ParametersTable>
      <MainTable
        tableHead={versionsTableHead}
        styles={{ tableLayout: 'fixed' }}
      >
        {versions.map((version) => (
          <VersionRow version={version} key={version.number} />
        ))}
      </MainTable>
    </>
  );
}
