import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

interface Props {
  list: {
    title: string;
    icon?: React.ReactNode;
    onClick?: () => void;
  }[];
}

export default function RecipeMenu({ list }: Props) {
  return (
    <MenuList sx={{ right: '8px', width: '260px', top: '52px' }}>
      {list.map(({ icon, title, onClick }) => (
        <MenuItem key={title} onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{title}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
}
