import Breadcrumbs from "components/Breadcrumbs";
import { Outlet } from "react-router-dom";
import styles from "./DefaultLayout.module.scss";

export default function DefaultLayout() {
  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <Breadcrumbs />
      </div>
      <div className={styles.body}>
        <Outlet />
      </div>
    </div>
  );
}