import { SvgIconProps, SvgIcon } from '@mui/material';

export default function ConnectionFalseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12ZM9.46875 10.5312L10.9375 12L9.46875 13.4688C9.15625 13.7812 9.15625 14.25 9.46875 14.5312C9.75 14.8438 10.2188 14.8438 10.5 14.5312L11.9688 13.0625L13.4688 14.5312C13.75 14.8438 14.2188 14.8438 14.5 14.5312C14.8125 14.25 14.8125 13.7812 14.5 13.4688L13.0312 12L14.5 10.5312C14.8125 10.25 14.8125 9.78125 14.5 9.46875C14.2188 9.1875 13.75 9.1875 13.4688 9.46875L11.9688 10.9688L10.5 9.46875C10.2188 9.1875 9.75 9.1875 9.46875 9.46875C9.15625 9.78125 9.15625 10.25 9.46875 10.5312Z'
        fill='#AC0D19'
      />
    </SvgIcon>
  );
}
