import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Box } from '@mui/material';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppDispatch } from 'redux/store';
import useTypedSelector from 'hooks/useTypedSelector';
import { isSaveButtonDisabledSelector } from 'redux/recipes/recipesSelectors';
import { getRecipeThunk } from 'redux/recipes/recipesThunk';
import { tabs } from 'constants/tabs';
import TabPanel from 'components/TabPanel';
import AlertDialog from 'components/dialogs/AlertDialog';
import styles from './Recipe.module.scss';

export default function Recipe() {
  const [tab, setTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isSaveButtonDisabled = useTypedSelector(isSaveButtonDisabledSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const goToRecipes = () => {
    navigate('/');
  };

  const handleSaveClick = () => {
    setIsDialogOpen(true);
  };

  useEffect(() => {
    id && dispatch(getRecipeThunk(+id));
  }, []);

  return (
    <>
      <div className={styles.root}>
        <Box className={styles.head}>
          <Tabs value={tab} onChange={handleChange}>
            {tabs.map(({ label, id }) => (
              <Tab label={t(label)} key={id} />
            ))}
          </Tabs>
        </Box>
        <Box className={styles.content}>
          {tabs.map(({ content, id }, index) => (
            <TabPanel value={tab} index={index} key={id}>
              {content}
            </TabPanel>
          ))}
        </Box>
      </div>
      <div className='default-layout_footer'>
        <Button
          startIcon={<ArrowBackIcon />}
          variant='secondary'
          onClick={goToRecipes}
        >
          {t('recipe-actions.recipe-overview')}
        </Button>
        <Box className={styles.buttons}>
          <Button
            startIcon={<ReplyIcon sx={{ transform: 'scale(-1, 1)' }} />}
            variant='contained'
          >
            {t('recipe-actions.release-recipe')}
          </Button>
          <Button
            startIcon={<SaveIcon />}
            variant='contained'
            onClick={handleSaveClick}
            disabled={isSaveButtonDisabled}
          >
            {t('recipe-actions.save-recipe')}
          </Button>
        </Box>
      </div>
      <AlertDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </>
  );
}
