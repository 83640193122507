import { SvgIconProps, SvgIcon } from '@mui/material';

export default function ExportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M9 13.75C9 13.3438 9.3125 13 9.75 13H15V9H11C10.4375 9 10 8.5625 10 8V4H4.5C3.65625 4 3 4.6875 3 5.5V18.5C3 19.3438 3.65625 20 4.5 20H13.5C14.3125 20 15 19.3438 15 18.5V14.5H9.75C9.3125 14.5 9 14.1875 9 13.75ZM11 4V8H15L11 4ZM20.75 13.2188L18.25 10.7188C17.9375 10.4375 17.4688 10.4375 17.1875 10.7188C16.875 11.0312 16.875 11.5 17.1875 11.7812L18.4375 13H15V14.5H18.4375L17.2188 15.75C17.0625 15.875 17 16.0625 17 16.25C17 16.4688 17.0625 16.6562 17.2188 16.7812C17.5 17.0938 17.9688 17.0938 18.25 16.7812L20.75 14.2812C21.0625 14 21.0625 13.5312 20.75 13.2188Z'
       
      />
    </SvgIcon>
  );
}
