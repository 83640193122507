import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const recipeSelector = (state: RootState) => state.recipes;

export const selectedRecipeSelector = createSelector(
  recipeSelector,
  (param) => param.selectedRecipe
);

export const devicesSelector = createSelector(
  recipeSelector,
  (param) => param.devices
);

export const selectedDeviceSelector = createSelector(
  recipeSelector,
  (param) => param.selectedDevice
);

export const isControlsDisabledSelector = createSelector(
  recipeSelector,
  (param) => param.isContolsDisabled
);

export const isSaveButtonDisabledSelector = createSelector(
  recipeSelector,
  (param) => param.isSaveButtonDisabled
);