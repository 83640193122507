import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipeType, Device, DeviceInfo, ComponentCfg, Parameter, RecipesState } from './recipesType';

const initialState: RecipesState = {
  selectedRecipe: null,
  devices: {},
  selectedDevice: null,
  isContolsDisabled: false,
  isSaveButtonDisabled: true,
};

export const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setSelectedRecipe: (state, action: PayloadAction<RecipeType>) => {
      state.selectedRecipe = action.payload;
    },

    setDevices: (state, { payload }: PayloadAction<Record<string, Device>>) => {
      state.devices = payload;
    },

    setSelectedDevice: (state, action: PayloadAction<DeviceInfo>) => {
      state.selectedDevice = action.payload;
    },

    setIsControlsDisabled: (state, action: PayloadAction<boolean>) => {
      state.isContolsDisabled = action.payload;
    },

    setIsSaveButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isSaveButtonDisabled = action.payload;
    },

    changeDevice: (
      state,
      action: PayloadAction<{
        id: number;
        content: Partial<Device>;
      }>
    ) => {
      const { id, content } = action.payload;
      state.devices = {
        ...state.devices,
        [id]: { ...state.devices[id], ...content },
      };
    },

    changeComponentCfg: (
      state,
      action: PayloadAction<{
        deviceId: number;
        componentId: string;
        content: Partial<ComponentCfg>;
      }>
    ) => {
      const { deviceId, componentId, content } = action.payload;
      state.devices = {
        ...state.devices,
        [deviceId]: {
          ...state.devices[deviceId],
          componentCfg: {
            ...state.devices[deviceId].componentCfg,
            [componentId]: {
              ...state.devices[deviceId].componentCfg[componentId],
              ...content,
            },
          },
        },
      };
    },

    changeParameter: (
      state,
      action: PayloadAction<{
        deviceId: number;
        componentId: string;
        content: Partial<Parameter>;
      }>
    ) => {
      const { deviceId, componentId, content } = action.payload;
      state.devices = {
        ...state.devices,
        [deviceId]: {
          ...state.devices[deviceId],
          parameters: {
            ...state.devices[deviceId].parameters,
            [componentId]: {
              ...state.devices[deviceId].parameters[componentId],
              ...content,
            },
          },
        },
      };
    },

    resetState: () => initialState,
  },
});

export const {
  setSelectedRecipe,
  resetState,
  setDevices,
  setSelectedDevice,
  changeDevice,
  changeComponentCfg,
  changeParameter,
  setIsControlsDisabled,
  setIsSaveButtonDisabled,
} = recipesSlice.actions;

export default recipesSlice.reducer;
