import ParametersTable from 'components/ParametersTable';
import useTypedSelector from 'hooks/useTypedSelector';
import { useAppDispatch } from 'redux/store';
import {
  devicesSelector,
  selectedDeviceSelector,
} from 'redux/recipes/recipesSelectors';
import { changeComponentCfg } from 'redux/recipes/recipesSlice';
import ParametersItem from 'components/ParametersTable/rows/ParametersItem';
import DevicesTable from 'components/DevicesTable';

export default function ComponentCfgTabPanel() {
  const dispatch = useAppDispatch();
  const devices = useTypedSelector(devicesSelector);
  const selectedDevice = useTypedSelector(selectedDeviceSelector)!;
  const componentCfg = devices[selectedDevice?.group]?.componentCfg;
  const componentCfgArr = Object.values(componentCfg || {});

  const onSwitchChange = (id: string) => {
    dispatch(
      changeComponentCfg({
        deviceId: selectedDevice?.group,
        componentId: id,
        content: { active: !componentCfg[id].active },
      })
    );
  };

  const onFavoriteChange = (id: string) => {
    dispatch(
      changeComponentCfg({
        deviceId: selectedDevice?.group,
        componentId: id,
        content: { favorite: !componentCfg[id].favorite },
      })
    );
  };

  return (
    <>
      <DevicesTable />
      <ParametersTable title={selectedDevice?.name}>
        {componentCfgArr.map((param, index) => (
          <ParametersItem
            key={param.id}
            item={param}
            index={index + 1}
            onSwitchChange={onSwitchChange}
            onFavoriteChange={onFavoriteChange}
          />
        ))}
      </ParametersTable>
    </>
  );
}
