import {
  Typography,
  Switch,
  IconButton,
  TableRow,
  TableCell,
} from '@mui/material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { GREY_MAIN, WHITE_SECONDARY } from 'constants/colors';
import useTypedSelector from 'hooks/useTypedSelector';
import { useAppDispatch } from 'redux/store';
import { Control } from 'redux/recipes/recipesType';
import { isControlsDisabledSelector } from 'redux/recipes/recipesSelectors';
import { setIsSaveButtonDisabled } from 'redux/recipes/recipesSlice';
import { isParameterType } from 'utils/types';
import Counter from 'components/Counter';
import styles from '../Parameters.module.scss';

interface Props {
  item: Control;
  index: number;
  onSwitchChange?: (id: string) => void;
  onCounterChange?: (id: string, value: number) => void;
  onFavoriteChange: (id: string) => void;
}

export default function ParametersItem({
  index,
  item,
  onSwitchChange,
  onFavoriteChange,
  onCounterChange,
}: Props) {
  const isControlsDisabled = useTypedSelector(isControlsDisabledSelector);
  const dispatch = useAppDispatch();
  const isParameter = isParameterType(item);
  const { active, favorite, desc, id } = item;

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsSaveButtonDisabled(false));
    onSwitchChange && onSwitchChange(id);
  };

  const handleFavorite = () => {
    dispatch(setIsSaveButtonDisabled(false));
    onFavoriteChange(id);
  };

  const handleCounterChange = (value: number) => {
    onCounterChange && onCounterChange(id, value);
  };

  return (
    <TableRow>
      <TableCell sx={{ minWidth: 24 }}>{index}</TableCell>
      <TableCell>{desc}</TableCell>
      <TableCell>
        <div className={styles.properties}>
          <Typography variant='body2' component='span'>
            {isParameter ? item.unit : '1'}
          </Typography>
          {isParameter ? (
            <Counter
              {...item.setPoint}
              onChange={handleCounterChange}
              disabled={isControlsDisabled}
            />
          ) : (
            <Switch
              checked={active}
              onChange={handleSwitchChange}
              disabled={isControlsDisabled}
            />
          )}
          <IconButton onClick={handleFavorite}>
            <StarRoundedIcon
              sx={{ color: favorite ? GREY_MAIN : WHITE_SECONDARY }}
            />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}
