import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SxProps,
} from '@mui/material';
import { TableHeadType } from 'types/tables';

interface Props {
  tableHead: TableHeadType[];
  children: React.ReactNode;
  styles?: SxProps;
}

export default function MainTable({ tableHead, children, styles }: Props) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table sx={styles}>
        <TableHead>
          <TableRow>
            {tableHead.map(({ title, width, colSpan }) => (
              <TableCell
                sx={{ width: `${width}%` }}
                key={title}
                colSpan={colSpan}
              >
                {t(title)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
