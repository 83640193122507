import { useState } from 'react';
import { Remove, Add } from '@mui/icons-material';
import { Button, Slider, TextField } from '@mui/material';
import styles from './DualCounter.module.scss';

const STEP = 5;

export default function DualCounter() {
  const [value, setValue] = useState([0, 10]);

  const addFirst = () => {
    if (value[0] + STEP > 100) return;

    setValue((prev) => [prev[0] + STEP, prev[1]]);
  };

  const removeFirst = () => {
    if (value[0] - STEP < 0) return;

    setValue((prev) => [prev[0] - STEP, prev[1]]);
  };

  const addSecond = () => {
    if (value[1] + STEP > 100) return;

    setValue((prev) => [prev[0], prev[1] + STEP]);
  };

  const removeSecond = () => {
    if (value[1] - STEP < 0) return;

    setValue((prev) => [prev[0], prev[1] - STEP]);
  };

  return (
    <div className={styles.root}>
      <div className={styles.control}>
        <Button
          variant='icon'
          onClick={removeFirst}
          disabled={false}
          className={styles.button}
        >
          <Remove />
        </Button>
        <TextField
          type='number'
          value={value[0]}
          variant='standard'
          InputProps={{
            readOnly: true,
            sx: {
              width: '48px',

              '& input': {
                textAlign: 'right',
              },

              '.MuiInputBase-input': {
                padding: 0,
              },
            },
          }}
        />
        <Button
          onClick={addFirst}
          disabled={false}
          className={styles.button}
          variant='icon'
        >
          <Add />
        </Button>
      </div>
      <div className={styles.control}>
        <Button
          onClick={removeSecond}
          disabled={false}
          className={styles.button}
          variant='icon'
        >
          <Remove />
        </Button>
        <TextField
          type='number'
          value={value[1]}
          variant='standard'
          InputProps={{
            readOnly: true,
            sx: {
              width: '48px',

              '& input': {
                textAlign: 'right',
              },

              '.MuiInputBase-input': {
                padding: 0,
              },
            },
          }}
        />
        <Button
          onClick={addSecond}
          disabled={false}
          className={styles.button}
          variant='icon'
        >
          <Add />
        </Button>
      </div>
      <Slider value={value} className={styles.slider} />
    </div>
  );
}
