import {
  Typography,
  Switch,
  IconButton,
  Box,
  TableRow,
  TableCell,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAppDispatch } from 'redux/store';
import { Control } from 'redux/recipes/recipesType';
import useTypedSelector from 'hooks/useTypedSelector';
import { isControlsDisabledSelector } from 'redux/recipes/recipesSelectors';
import {
  changeComponentCfg,
  changeParameter,
  setIsSaveButtonDisabled,
} from 'redux/recipes/recipesSlice';
import { isParameterType } from 'utils/types';
import Counter from 'components/Counter';
import styles from '../Parameters.module.scss';

interface Props {
  item: Control;
  group: number;
  name: string;
}

export default function FavoriteItem({ item, group, name }: Props) {
  const dispatch = useAppDispatch();
  const isControlsDisabled = useTypedSelector(isControlsDisabledSelector);

  const isParameter = isParameterType(item);
  const { id, desc, active } = item;

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeComponentCfg({
        deviceId: group,
        componentId: id,
        content: { active: !active },
      })
    );
    dispatch(setIsSaveButtonDisabled(false));
  };

  const onCounterChange = (value: number) => {
    isParameter &&
      dispatch(
        changeParameter({
          deviceId: group,
          componentId: id,
          content: { setPoint: { ...item.setPoint, value: value } },
        })
      );
    dispatch(setIsSaveButtonDisabled(false));
  };

  const onRemoveFavorite = () => {
    const dispatchFunc = isParameter ? changeParameter : changeComponentCfg;
    dispatch(
      dispatchFunc({
        deviceId: group,
        componentId: id,
        content: { favorite: false },
      })
    );
    dispatch(setIsSaveButtonDisabled(false));
  };

  return (
    <TableRow>
      <TableCell>{group}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{desc}</TableCell>
      <TableCell>
        <Box className={styles.properties}>
          <Typography variant='body2' component='span'>
            {isParameter ? item.unit : '1'}
          </Typography>
          {isParameter ? (
            <Counter
              {...item.setPoint}
              onChange={onCounterChange}
              disabled={isControlsDisabled}
            />
          ) : (
            <Switch
              checked={active}
              onChange={onSwitchChange}
              disabled={isControlsDisabled}
            />
          )}
        </Box>
      </TableCell>
      <TableCell>
        <IconButton
          sx={{
            float: 'right',
          }}
          onClick={onRemoveFavorite}
        >
          <CloseRoundedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
