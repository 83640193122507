import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.scss';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import store from './redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BLACK,
  BLACK_20,
  GREY_15,
  GREY_20,
  GREY_30,
  GREY_40,
  GREY_60,
  GREY_MAIN,
  GREY_LIGHT,
  GRADIENT_ONE,
  GRADIENT_TWO,
  WHITE_DEFAULT,
  WHITE_PRIMARY,
  WHITE_SECONDARY,
} from 'constants/colors';

import './i18n';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
    icon: true;
  }
}

const theme = createTheme({
  typography: {
    h1: {
      fontSize: 16,
      color: '#ff0000',
    },
    body1: {
      fontSize: 20,
    },
    body2: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 400,
      color: GREY_MAIN,
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      color: GREY_MAIN,
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 400,
      color: GREY_MAIN,
    },
  },

  components: {
    // buttons
    MuiButton: {
      variants: [
        {
          props: { variant: 'secondary' },
          style: {
            border: `1px solid ${GREY_LIGHT}`,
            background: GRADIENT_TWO,
            color: GREY_MAIN,
          },
        },
        {
          props: { variant: 'icon' },
          style: {
            border: `1px solid ${GREY_LIGHT}`,
            background: GRADIENT_TWO,
            borderRadius: '2px',
            minWidth: 'auto',
            height: '48px',
            width: '48px',
            transition: 'color .3s',
            padding: '12px',
            color: GREY_MAIN,
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '16px',
          borderRadius: '2px',
          fontWeight: '400',
          textTransform: 'capitalize',
          padding: '12px 20px',
          height: '48px',
          background: GRADIENT_ONE,
          boxShadow: 'none',
          border: `1px solid ${GREY_MAIN}`,

          '.MuiButton-startIcon': {
            marginRight: '8px',
          },

          '.MuiButton-startIcon, .MuiButton-endIcon': {
            '.MuiSvgIcon-root': {
              transition: 'transform .3s',
            },

            '>:nth-of-type(1)': {
              fontSize: '24px',
            },
          },

          '&.Mui-disabled': {
            color: BLACK_20,
          },
        },
      },
    },

    //icon button
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          border: 'none',
          background: 'transparent',
          padding: '12px',
          minWidth: 'auto',
          width: '48px',

          '&:hover': {
            background: 'transparent',
          },
        },
      },
    },

    //checkbox
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: GREY_MAIN,
          },
        },
      },
    },

    // breadcrumbs
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '22px',
          lineHeight: '26px',
          fontWeight: 400,
          textDecoration: 'none',
          color: GREY_MAIN,

          a: {
            color: 'inherit',
            textDecoration: 'none',
            transition: 'color .3s',

            '&:hover': {
              color: BLACK,
            },
          },

          '.MuiTypography-body1': {
            fontWeight: 500,
            fontSize: '22px',
            color: GREY_MAIN,
          },
        },
      },
    },

    //menu
    MuiList: {
      styleOverrides: {
        root: {
          border: `1px solid ${GREY_LIGHT}`,
          background: WHITE_DEFAULT,
          position: 'absolute',
          zIndex: 10,
          padding: 0,
          borderRadius: '2px',

          '.MuiMenuItem-root': {
            padding: '3px 16px',
            minHeight: '48px',
            borderBottom: `1px solid ${GREY_LIGHT}`,

            '.MuiListItemText-root': {
              marginLeft: '8px',
            },

            '&:last-of-type': {
              borderBottom: 'none',
            },

            '& span': {
              fontSize: '16px',
            },

            '.MuiListItemIcon-root': {
              minWidth: 'auto',
            },
          },
        },
      },
    },

    // form control
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            borderRadius: '2px',
            fontSize: '16px',
            background: WHITE_DEFAULT,
            height: '48px',

            '&:after, &:before': {
              border: 'none',
            },

            '&:hover:not(.Mui-disabled, .mui-error):before': {
              border: 'none',
            },

            '&.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                borderWidth: '1px',
              },
            },
          },

          '.MuiInputBase-input': {
            padding: '10px 8px',
            height: '48px',
            color: BLACK,
            textAlign: 'right',

            '&::placeholder': {
              color: GREY_60,
              fontStyle: 'italic',
            },
          },

          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${GREY_LIGHT}`,
            transition: 'border-color .3s',
          },
        },
      },
    },

    // table
    MuiTableContainer: {
      styleOverrides: {
        root: {
          height: '100%',
          background: WHITE_DEFAULT,
          overflowY: 'auto',
          border: `1px solid ${GREY_30}`,

          '.MuiTable-root': {
            borderColor: WHITE_DEFAULT,
            backgroundColor: WHITE_DEFAULT,
            overflowY: 'auto',

            '	.MuiTableBody-root': {
              '.MuiTableRow-root': {
                transition: 'background-color .3s',
                cursor: 'pointer',

                '&:nth-of-type(even)': {
                  backgroundColor: GREY_15,
                },

                '&.Mui-selected': {
                  backgroundColor: WHITE_SECONDARY,
                },

                '&:hover': {
                  backgroundColor: WHITE_SECONDARY,
                },

                '.MuiTableCell-root': {
                  height: '56px',
                  padding: '0 8px',
                  color: GREY_MAIN,
                  fontSize: '16px',
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',

                  '&:first-of-type': {
                    padding: '0 8px 0 16px',
                  },
                },
              },
            },

            '.MuiTableHead-root': {
              '.MuiTableCell-root': {
                color: GREY_MAIN,
                height: '40px',
                padding: '0 0 0 8px',
                fontSize: '14px',
                fontWeight: 500,
              },
            },
          },
        },
      },
    },

    //slider
    MuiSlider: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: 0,

          '.MuiSlider-thumb': {
            display: 'none',
          },

          '.MuiSlider-rail': {
            color: GREY_20,
          },

          '.MuiSlider-track': {
            color: GREY_MAIN,
            height: '4px',
            border: 'none',
          },
        },
      },
    },

    //switch
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '56px',
          height: '32px',

          '.MuiSwitch-switchBase': {
            padding: '4px',

            '&.Mui-checked': {
              transform: 'translateX(24px)',
              color: WHITE_DEFAULT,

              '+ .MuiSwitch-track': {
                backgroundColor: GREY_MAIN,
                opacity: 1,
              },
            },
          },

          '.MuiSwitch-thumb': {
            width: '24px',
            height: '24px',
            borderRadius: '24px',
            boxShadow: 'none',
          },

          '.MuiSwitch-track': {
            borderRadius: '32px',
            border: 0,
            opacity: 1,
            backgroundColor: GREY_40,
          },
        },
      },
    },

    // tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '48px',

          '.MuiTabs-indicator': {
            display: 'none',
          },

          '.MuiTab-root': {
            backgroundColor: GREY_30,
            color: GREY_MAIN,
            fontSize: '16px',
            height: '19px',
            fontWeight: 400,
            transition: 'background-color .3s',
            textTransform: 'capitalize',
            borderRight: `1px solid ${GREY_15}`,

            '&.Mui-selected': {
              color: GREY_MAIN,
              backgroundColor: WHITE_PRIMARY,
            },
          },
        },
      },
    },

    //dialog
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            borderRadius: 0,
            boxShadow: 'none',

            '.MuiDialogTitle-root': {
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '26px',
            },

            '.MuiDialogContent-root': {
              padding: '16px',

              '.MuiDialogContentText-root': {
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 400,
                color: GREY_MAIN,
                padding: '16px 0',
              },
            },

            '.MuiDialogActions-root': {
              padding: '0 16px 16px',
            },
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
